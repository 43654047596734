import React, { useEffect, useState } from 'react'
import HeaderImage from './HeaderImage'
import ServiceNav from './ServiceNav'
import img1 from '../images/shipping-banner.png'
import LoadingScreen from './LoadingScreen'
import support from '../images/support.svg'
import efficiency from '../images/efficiency.svg'

function OceanShipping() {
    const [loading, setLoading] = useState(true)
    const loadcount = []

    useEffect(() => {
        var urls = [img1]
        urls.forEach((url) => {
            var img = new Image()
            img.onload = function () {
                loadcount.push(img)
                if (loadcount.length === urls.length) {
                    setLoading(false)
                }
            }
            img.src = url
            if (img.complete) img.onload()
        })
    }, [])

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : (
                <div>
                    <HeaderImage classStyle="ocean-header" text="SHIPPING" />
                    <div className="service-page row no-gutters">
                        <div className="service-L">
                            <ServiceNav active="ocean" />
                        </div>

                        <div className="service-R">
                            <h2>
                                Make your trusted ocean freight forwarding
                                partner.{' '}
                            </h2>
                            <p>
                                Everwin is a trusted and dependable partner for
                                international ocean freight forwarding by its
                                valued clients. Through our global
                                transportation network and overseas logistics
                                partners, we ship all commodities - including
                                temperature controlled and dangerous goods. We
                                offer charters for very large or specialty
                                cargo, provide 24 hour customer support team,
                                and much more. Everything from simple FCL
                                shipping to splitting LCL shipments. We work
                                with top carriers to secure you reliable space
                                at competitive rates.
                                <br />
                                <br />
                                Everwin provides worldwide logistical services
                                to facilitate the worldwide shipment of your sea
                                and air freight. Below are some of the different
                                areas we service for shipping your cargo by sea
                                and air.
                            </p>
                            <div className="row">
                                <div className="col">
                                    <img
                                        src={support}
                                        className="services-icons"
                                        alt="support"
                                    />

                                    <p className="sub-page-icon-text">
                                        Get Real-Time Visibility and Sail With
                                        Trusted Partners Service With Human
                                        Support
                                    </p>
                                </div>
                                <div className="col">
                                    <img
                                        src={efficiency}
                                        className="services-icons"
                                        alt="support"
                                    />
                                    <p className="sub-page-icon-text">
                                        Efficient Ocean
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default OceanShipping
