function FormSubmit() {

    return (
        <div id='form-submit-pg'>
            <h3>Thank you for your submission, we'll be in touch shortly!</h3>   
        </div>
    )
}

export default FormSubmit
