import { useState } from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

function Metrics() {
    const [viewPortEntered, setViewPortEntered] = useState(false)

    return (
        <div id="metrics">
            <div className="row">
                <div className="col-md">
                    <div id="title">
                        <CountUp
                            start={0}
                            end={20}
                            start={viewPortEntered ? null : 0}
                        >
                            {({ countUpRef }) => {
                                return (
                                    <VisibilitySensor
                                        active={!viewPortEntered}
                                        onChange={(isVisible) => {
                                            if (isVisible) {
                                                setViewPortEntered(true)
                                            }
                                        }}
                                        delayedCall
                                    >
                                        <h ref={countUpRef} />
                                    </VisibilitySensor>
                                )
                            }}
                        </CountUp>
                        +
                    </div>
                    <h3>years in business</h3>
                </div>
                <div className="col-md">
                    <div id="title">
                        <CountUp
                            start={0}
                            end={166}
                            start={viewPortEntered ? null : 0}
                        >
                            {({ countUpRef }) => {
                                return (
                                    <VisibilitySensor
                                        active={!viewPortEntered}
                                        onChange={(isVisible) => {
                                            if (isVisible) {
                                                setViewPortEntered(true)
                                            }
                                        }}
                                        delayedCall
                                    >
                                        <h ref={countUpRef} />
                                    </VisibilitySensor>
                                )
                            }}
                        </CountUp>
                        +
                    </div>
                    <h3>customers</h3>
                </div>
                <div className="col-md">
                    <div id="title">
                        <CountUp
                            start={159800}
                            end={160000}
                            start={viewPortEntered ? null : 0}
                        >
                            {({ countUpRef }) => {
                                return (
                                    <VisibilitySensor
                                        active={!viewPortEntered}
                                        onChange={(isVisible) => {
                                            if (isVisible) {
                                                setViewPortEntered(true)
                                            }
                                        }}
                                        delayedCall
                                    >
                                        <h ref={countUpRef} />
                                    </VisibilitySensor>
                                )
                            }}
                        </CountUp>
                        +
                    </div>
                    <h3>sq. ft warehousing space</h3>
                </div>
            </div>
        </div>
    )
}

export default Metrics
