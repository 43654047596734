import React from 'react'
import HeaderImage from './HeaderImage'
import ServiceNav from './ServiceNav'

function BusinessSolutions() {
    return (
        <div>
            <HeaderImage
                classStyle="business-header"
                text="LOGISTICS BUSINESS SOLUTIONS"
            />
            <div className="service-page row no-gutters">
                <div className="service-L">
                    <ServiceNav active="business" />
                </div>

                <div className="service-R">
                    <ul>
                        <li>One stop Logistics consultant service</li>
                        <li>
                            Cross border Ecommerce end to end service solutions
                        </li>
                        <li>Hassle free integrations</li>
                        <li>
                            Tailor Crow funding
                            <br />
                            <br />
                            --Brilliant idea needs full back ups, kickstarter or
                            Indiegogo program
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default BusinessSolutions
