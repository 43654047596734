import React from 'react'
import HeaderImage from './HeaderImage'
import ServiceNav from './ServiceNav'

function CustomBroker() {
    return (
        <div>
            <HeaderImage classStyle="customs-header" text="CUSTOMS BROKER" />
            <div className="service-page row no-gutters">
                <div className="service-L">
                    <ServiceNav active="broker" />
                </div>

                <div className="service-R">
                    <ul>
                        <li>
                            <p>Customs gateway in major China airports</p>
                        </li>
                        <li>
                            <p>
                                China import Customs service DAP/DDP/ with B2B
                                mode
                            </p>
                        </li>
                        <li>
                            <p>
                                China import customs service DDP/DDU with B2C
                                mode <br />
                                -- BC 1210 bonded service
                                <br />
                                -- CC 9610 service
                            </p>
                            <p>
                                To find out more, email{' '}
                                <a href="mailto:import@evertowin.com">
                                    <span>import@evertowin.com</span>
                                </a>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CustomBroker
