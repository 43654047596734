import React from 'react'
import HeaderImage from './HeaderImage'
import img1 from '../images/landing.png'
import { useEffect, useState } from 'react'
import LoadingScreen from './LoadingScreen'

function Services() {
    const [loading, setLoading] = useState(true)
    const loadcount = []

    useEffect(() => {
        var urls = [img1]
        urls.forEach((url) => {
            var img = new Image()
            img.onload = function () {
                loadcount.push(img)
                if (loadcount.length === urls.length) {
                    setLoading(false)
                }
            }
            img.src = url
            if (img.complete) img.onload()
        })
    }, [])

    return loading ? (
        <LoadingScreen />
    ) : (
        <div>
            <HeaderImage classStyle="service-image" text="TRACE PACKAGE" />
            <div className="about-text">
                <p> Enter your tracking number below to retreieve information about your package.</p>
            </div>
            <div className="trace" style={{margin: "10vh 25vw"}}>
                <iframe style={{ height: 400, width: '50vw'}} src="https://mms.intelink.net.cn/oms/#/logisticsTracking?companyId=10270"></iframe>
            </div>
        </div>
    )
}

export default Services
