import ship from '../../images/cruise.svg'
import plane from '../../images/airplane.svg'
import truck from '../../images/truck.svg'
import warehouse from '../../images/warehouse.svg'
import broker from '../../images/broker.svg'
import border from '../../images/germany.svg'
function ServicesIcons() {
    return (
        <div id="services-icons-section">
            <h2>OUR SERVICES</h2>
            <p>
                EVERWIN specializes in international air, ocean postal and
                e-Commerce projects involving cargo and freight forwarding.
            </p>
            <div className="row">
                <div className="col service-card">
                    <img src={ship} className="services-icons" alt="ship" />
                    <p>
                        <b>Ocean</b>
                    </p>
                </div>
                <div className="col service-card">
                    <img src={plane} className="services-icons" alt="plane" />
                    <p>
                        <b>Airfreight</b>
                    </p>
                </div>
                <div className="col service-card">
                    <img src={truck} className="services-icons" alt="truck" />
                    <p>
                        <b>Ground Transportation</b>
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col service-card">
                    <img
                        src={warehouse}
                        className="services-icons"
                        alt="warehouse"
                    />
                    <p>
                        <b>Warehousing and Fulfillment</b>
                    </p>
                </div>
                <div className="col service-card">
                    <img src={border} className="services-icons" alt="border" />
                    <p>
                        <b>Cross-Border Service Provider</b>
                    </p>
                </div>
                <div className="col service-card">
                    <img src={broker} className="services-icons" alt="broker" />
                    <p>
                        <b>Supply Chain Consultant</b>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ServicesIcons
