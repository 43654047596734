import shipped from '../../images/shipped-icon.png'
import warehouse from '../../images/warehouse-icon.png'
import business from '../../images/business-icon.png'
import parcel from '../../images/parcel.svg'
import handshake from '../../images/handshake.svg'

function ServiceIntro() {
    return (
        <div id="service-landing" className="justify-content-center">
            <div className="service-box">
                <img
                    src={shipped}
                    className="landing-boxes"
                    style={{ marginBottom: '1em' }}
                />
                <h3>SHIPPING</h3>
                <p>143+ countries</p>
            </div>
            <div className="service-box">
                <img
                    src={warehouse}
                    className="landing-boxes"
                    style={{ marginBottom: '1em' }}
                />
                <h3>WAREHOUSE AND FULFILLMENT</h3>
            </div>
            <div className="service-box">
                <img
                    src={parcel}
                    className="landing-boxes"
                    style={{ marginBottom: '1em' }}
                />
                <h3>PARCEL</h3>
                <p>50000+ daily processing</p>
            </div>
            <div className="service-box">
                <img
                    src={handshake}
                    className="landing-boxes"
                    style={{ marginBottom: '1em' }}
                />
                <h3>PARTNER</h3>
                <p>99+ worldwide</p>
            </div>{' '}
            <div className="service-box">
                <img
                    src={business}
                    className="landing-boxes"
                    style={{ marginBottom: '1em' }}
                />
                <h3>BUSINESS SOLUTIONS</h3>
                <p>365/7/24 supports</p>
            </div>
        </div>
    )
}

export default ServiceIntro
