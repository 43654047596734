import HeaderImage from './HeaderImage'
import pin from '../images/pin.svg'
import phone from '../images/phone.png'
import hq from '../images/office2.jpg'
import office from '../images/office1.jpg'
import warehouse1 from '../images/warehouseChina.jpg'
import warehouse2 from '../images/warehouseCanada.jpg'
import banner from '../images/shanghai.png'
import { useEffect, useState } from 'react'
import LoadingScreen from './LoadingScreen'
import ContactUs from './ContactUs'

function Location() {
    const [loading, setLoading] = useState(true)
    const loadcount = []

    useEffect(() => {
        var urls = [hq, office, warehouse1, warehouse2, banner]
        urls.forEach((url) => {
            var img = new Image()
            img.onload = function () {
                loadcount.push(img)
                if (loadcount.length === urls.length) {
                    setLoading(false)
                }
            }
            img.src = url
            if (img.complete) img.onload()
        })
    }, [loadcount])

    return loading ? (
        <LoadingScreen />
    ) : (
        <>
            <HeaderImage classStyle="location-image" text="LOCATIONS" />
            <div className="location">
                <h1 className="location-title" style={{ marginBottom: '5vh' }}>
                    OFFICES
                </h1>
                <div className="row location-squares">
                    <div className="col-md text-center">
                        <img
                            src={hq}
                            alt="headquarters"
                            className="location-squares-img"
                        />
                        <div class="text-center">
                            <div className="location-text">
                                <h1>Headquarters</h1>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '1em',
                                    }}
                                >
                                    <img
                                        src={pin}
                                        className="contact-img"
                                        style={{ marginRight: '8px' }}
                                    />
                                    <p>
                                        Room 12A03-04, No. 19 Wu Ning Road,
                                        Putuo District, Shanghai 200042, China
                                    </p>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '.5em',
                                    }}
                                >
                                    <img
                                        src={phone}
                                        className="contact-img"
                                        style={{ marginRight: '8px' }}
                                    />
                                    <p style={{ marginRight: '1em' }}>
                                        <b>Tel:</b> 86-21-52868600
                                    </p>

                                    <p>
                                        <b>Fax:</b> 86-21-52868600
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md text-center">
                        <img
                            src={office}
                            alt="office"
                            className="location-squares-img"
                        />
                        <div class="text-center">
                            <div class="text-center">
                                <div className="location-text">
                                    <h1>Canada</h1>
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginTop: '1em',
                                        }}
                                    >
                                        <img
                                            src={pin}
                                            className="contact-img"
                                            style={{ marginRight: '8px' }}
                                        />
                                        <p>
                                            118-7900 Westminster Highway,
                                            Richmond, BC, V6X 1A5, Canada
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginTop: '.5em',
                                        }}
                                    >
                                        <img
                                            src={phone}
                                            className="contact-img"
                                            style={{ marginRight: '8px' }}
                                        />
                                        <p>
                                            <b>Tel:</b> 1-7789298929{' '}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h1
                    className="location-title"
                    style={{ marginBottom: '5vh', paddingTop: '2em' }}
                >
                    WAREHOUSES
                </h1>
                <div className="row location-squares">
                    <div className="col-md text-center">
                        <img
                            src={warehouse1}
                            alt="warehouse"
                            className="location-squares-img"
                        />
                        <div class="text-center">
                            <div className="location-text">
                                <h1>China </h1>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '1em',
                                    }}
                                >
                                    <img
                                        src={pin}
                                        className="contact-img"
                                        style={{ marginRight: '8px' }}
                                    />
                                    <p>
                                        No. 689 Bo Xue South Road, Jiading
                                        District, 201801, Shanghai
                                    </p>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '.5em',
                                    }}
                                >
                                    <img
                                        src={phone}
                                        className="contact-img"
                                        style={{ marginRight: '8px' }}
                                    />
                                    <p style={{ marginRight: '1em' }}>
                                        <b>Tel:</b> 86-21-59169576
                                    </p>

                                    <p>
                                        <b>Tel:</b> 86-21-69126808
                                    </p>
                                </div>
                                <p></p>
                                <p></p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md text-center">
                        <img
                            src={warehouse2}
                            alt="warehouse"
                            className="location-squares-img"
                        />
                        <div className="location-text">
                            <h1>Canada</h1>
                            <div style={{ display: 'flex', marginTop: '1em' }}>
                                <img
                                    src={pin}
                                    className="contact-img"
                                    style={{ marginRight: '8px' }}
                                />
                                <p>
                                    12851, No.5 Road, Richmond, BC, V7A 4E9,
                                    Canada{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUs />
        </>
    )
}

export default Location
