import {
    Map,
    GoogleApiWrapper,
    Marker,
    InfoWindow,
    InfoBox,
} from 'google-maps-react'
import phone from '../images/phone.png'
import mail from '../images/mail.png'
import pin from '../images/pin.svg'
import { useState } from 'react'

const mapStyles = {
    width: '100%',
    height: 700,
    position: 'relative',
}

function MapContainer(props) {
    const [location, setLocation] = useState('118-7900 Westminster Highway')
    const [location2, setLocation2] = useState('Richmond, BC, V6X 1A5, Canada')
    return (
        <div id="contact-map-section">
            <div className="contact-card">
                <h3>CONTACT US</h3>
                <div className="row no-gutters">
                    <img src={phone} className="contact-img" />
                    <p className="contact-item">86-21-62096606, 6608</p>
                </div>
                <div className="row no-gutters">
                    <img src={mail} className="contact-img" />
                    <p className="contact-item">info@evertowin.com</p>
                </div>
                <div className="row no-gutters">
                    <img src={pin} className="contact-img" />
                    <div className="contact-item">
                        <p>{location}</p>
                        <p>{location2}</p>
                    </div>
                </div>
            </div>
            <Map
                google={props.google}
                zoom={3}
                style={mapStyles}
                className="map"
                initialCenter={{ lat: 47, lng: 180 }}
            >
                <Marker
                    key="0"
                    title="China"
                    position={{ lat: 31.25539, lng: 121.39326 }}
                    onClick={() => {
                        setLocation('Room 12A03-04, No. 19 Wu Ning Road')
                        setLocation2('Putuo District, Shanghai 200042, China')
                    }}
                ></Marker>
                <Marker
                    key="1"
                    title="Canada"
                    position={{ lat: 49.121284, lng: -123.092792 }}
                    onClick={() => {
                        setLocation('12851, No.5 Road')
                        setLocation2('Richmond, BC, V7A 4E9, Canada')
                    }}
                />
                <Marker
                    key="2"
                    title="Canada"
                    position={{ lat: 49.16991, lng: -123.137543 }}
                    onClick={() => {
                        setLocation('118-7900 Westminster Highway')
                        setLocation2('Richmond, BC, V6X 1A5, Canada')
                    }}
                />
                <Marker
                    key="3"
                    title="China"
                    position={{ lat: 31.388639, lng: 121.263046 }}
                    onClick={() => {
                        setLocation('No. 689 Bo Xue South Road')
                        setLocation2('Jiading District, 201801, Shanghai')
                    }}
                />
            </Map>
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAUmi1W1FT2s1UGYlGtWuaSWe2GoPbESD0',
})(MapContainer)
