import React, { useEffect, useState } from 'react'
import HeaderImage from './HeaderImage'
import ServiceNav from './ServiceNav'
import img1 from '../images/ecommerce.png'
import img2 from '../images/ecommerce-img.jpg'
import LoadingScreen from './LoadingScreen'

function Ecommerce() {
    const [loading, setLoading] = useState(true)
    const loadcount = []

    useEffect(() => {
        var urls = [img1]
        urls.forEach((url) => {
            var img = new Image()
            img.onload = function () {
                loadcount.push(img)
                if (loadcount.length === urls.length) {
                    setLoading(false)
                }
            }
            img.src = url
            if (img.complete) img.onload()
        })
    }, [])

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : (
                <div>
                    <HeaderImage
                        classStyle="ecommerce-header"
                        text="ECOMMERCE SHIPPING"
                    />
                    <div className="service-page row no-gutters">
                        <div className="service-L">
                            <ServiceNav active="ecommerce" />
                        </div>

                        <div className="service-R">
                            <h2>End to End service with 9 sectors map.</h2>
                            <p>
                                To find out more, email{' '}
                                <a href="mailto:ec@evertonwin.com">
                                    ec@evertonwin.com
                                </a>
                            </p>
                            <img src={img2} alt="e-commerce" width="100%" />
                            <div className="row no-gutters">
                                <div className="col-sm">
                                    <h3>Courier</h3>
                                    <ul>
                                        <li>
                                            Everwin courier , China – Canada
                                            traffic
                                        </li>
                                        <li>
                                            Fedex, UPS, DHL, integrate service
                                            provider accounts.
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm">
                                    <h3>Mailing</h3>
                                    <ul>
                                        <li>
                                            <p>
                                                Post service with coordinate
                                                multiple States
                                            </p>
                                        </li>
                                        <li>
                                            <p>Posts</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-sm">
                                    <h3>Customs brokage</h3>
                                    <ul>
                                        <li>
                                            Professional team with decades
                                            experience of liaison with Customs
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm">
                                    <h3>Local transport</h3>
                                    <ul>
                                        <li>
                                            Dedicated Service – Trucking, Van,
                                            Motor
                                        </li>
                                        <li>FTL / LTL</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Ecommerce
