import { NavLink } from 'react-router-dom'

import logo from '../images/logo.png'

function Footer(props) {
    return (
        <div id="footer">
            <div id="footer-content" className="row">
                <div className="col-md">
                    <NavLink to="/">
                        <img src={logo} alt="logo" className="footer-logo" />
                    </NavLink>
                    <br />
                    <p className="connected-text">Stay connected with us</p>
                    <div className="contact-number">86 21 62096606</div>
                    <p className="connected-text">Stay connected with us</p>
                    <div className="row no-gutters contact-row">
                        <div className="contact-div">
                            <div
                                id="wechat"
                                onClick={() => {
                                    props.setCert('qr-wechat')
                                }}
                            />
                        </div>
                        <div className="contact-div">
                            <div
                                id="whatsapp"
                                onClick={() => {
                                    props.setCert('qr-whatsapp')
                                }}
                            />
                        </div>
                    </div>

                    {/* <img src={qr} alt="qr code" className="qr-code" />*/}
                </div>
                <div className="col-md">
                    <h3>Services</h3>
                    <div className="footer-link">
                        <NavLink to="/service-ocean-shipping">Ocean</NavLink>
                    </div>
                    <div className="footer-link">
                        <NavLink to="/service-airfreight">Air Freight</NavLink>
                    </div>
                    <div className="footer-link">
                        <NavLink to="/service-warehouse">
                            Warehouse and FullFillment
                        </NavLink>
                    </div>
                    <div className="footer-link">
                        <NavLink to="/service-business-solutions">
                            Business Solutions
                        </NavLink>
                    </div>
                    <div className="footer-link">
                        <NavLink to="/service-customs-broker">
                            Customs Broker
                        </NavLink>
                    </div>
                    <div className="footer-link">
                        <NavLink to="/service-ecommerce-shipping">
                            E-commerce Shipping
                        </NavLink>
                    </div>
                </div>
                <div className="col-md footer-col" id="contact-us">
                    <h3>Inquiries</h3>
                    <p className="inquiries-sub">General</p>

                    <a href="mailto:info@evertonwin.com">
                        <p className="contact-item">info@evertowin.com</p>
                    </a>

                    <p className="inquiries-sub">Custom Broker</p>
                    <a href="mailto:import@evertonwin.com">
                        <p className="contact-item">import@evertowin.com</p>
                    </a>

                    <p className="inquiries-sub">Ecommerce</p>
                    <a href="mailto:ec@evertonwin.com">
                        <p className="contact-item">ec@evertonwin.com</p>
                    </a>
                    <p className="inquiries-sub">Warehousing</p>
                    <a href="mailto:wh@evertonwin.com">
                        <p className="contact-item">wh@evertonwin.com</p>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer
