import Landing from './components/landing-page/Landing'
import CustomNav from './components/CustomNav'
import Footer from './components/Footer'
import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import About from './components/About'
import Services from './components/Services'
import WarehouseAndFulfillment from './components/WarehouseAndFulfillment'
import FormSubmit from './components/FormSubmit'
import OceanShipping from './components/OceanShipping'
import Airfreight from './components/Airfreight'
import FormToggle from './components/FormToggle'
import Location from './components/Location'
import BusinessSolutions from './components/BusinessSolutions'
import CustomBroker from './components/CustomBroker'
import acCert from './images/ac-cert.png'
import afbnCert from './images/abfn-cert.png'
import Ecommerce from './components/Ecommerce'
import qr from './images/QR.png'
import qr2 from './images/qr2.jpeg'

function App() {
    const [cert, setCert] = useState('')

    return (
        <React.Fragment>
            <CustomNav />
            {cert !== '' && (
                <div className="mask">
                    <div className="certificate-div">
                        {
                            <div
                                onClick={() => {
                                    setCert('')
                                }}
                                className="popup-close is-clickable"
                            >
                                x
                            </div>
                        }
                        {cert === 'ac' && (
                            <img src={acCert} className="certificate-popup" />
                        )}
                        {cert === 'afbn' && (
                            <img src={afbnCert} className="certificate-popup" />
                        )}

                        {cert === 'qr-wechat' && (
                            <img src={qr} className="qr-popup" />
                        )}
                        {cert === 'qr-whatsapp' && (
                            <img src={qr2} className="qr-popup" />
                        )}
                    </div>
                </div>
            )}
            <Switch>
                <div className="content">
                    <Route path="/" component={Landing} exact />
                    <Route path="/about" exact>
                        <About setCert={(val) => setCert(val)} />
                    </Route>
                    <Route
                        path="/service-warehouse"
                        component={WarehouseAndFulfillment}
                        exact
                    />
                    <Route
                        path="/service-airfreight"
                        component={Airfreight}
                        exact
                    />
                    <Route
                        path="/service-ocean-shipping"
                        component={OceanShipping}
                        exact
                    />
                    <Route
                        path="/service-business-solutions"
                        component={BusinessSolutions}
                        exact
                    />
                    <Route
                        path="/service-customs-broker"
                        component={CustomBroker}
                        exact
                    />
                    <Route
                        path="/service-ecommerce-shipping"
                        component={Ecommerce}
                        exact
                    />
                    <Route path="/trace" component={Services} exact />
                    <Route path="/location" component={Location} exact />
                    <Route path="/form-submit" component={FormSubmit} exact />
                </div>
            </Switch>
            <FormToggle />
            <Footer setCert={(val) => setCert(val)} />
        </React.Fragment>
    )
}

export default App
